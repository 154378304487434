import React, { useState, useRef, useContext } from "react";
import { Mutation } from "@apollo/client/react/components";
import styled from "styled-components";
import nanoid from "nanoid";
import { Formik } from "formik";
import * as Yup from "yup";
import Context from "@common/website/components/context/shared_utils";

import { CREATE_VALDRES_LEAD_MUTATION } from "@common/website/gql/mutations/lead.gql";

import adImage from "@common/website/assets/images/valdreskampanje/top.jpg";

import Status from "@common/website/components/routes/properties/shared/register/status";
import FormField from "@common/website/components/shared/form/lib/form_field";
import SubmitButton from "@common/website/components/shared/form/lib/submit_button";

const PRODUCTION = process.env.NODE_ENV === "production";

const initialFormState = {
	values: {
		name: {
			placeholder: "Navn *",
			width: "100%",
			value: PRODUCTION ? "" : "Test",
			validation: Yup.string()
				.min(2, "Fornavnet må være minst to bokstaver")
				.required("Påkrevd")
		},
		zip: {
			placeholder: "Postnr *",
			width: "30%",
			value: PRODUCTION ? "" : "1234",
			validation: Yup.string()
				.min(4, "Ugylding postnummer")
				.max(4, "Ugylding postnummer")
				.matches(/^[0-9]*$/, "Ugyldig postnummer")
				.required("Påkrevd")
		},
		phone: {
			placeholder: "Telefon *",
			width: "70%",
			value: PRODUCTION ? "" : "12345678",
			validation: Yup.string()
				.matches(/^\s*(\d\s*){8}$/, { excludeEmptyString: true, message: "Ugyldig telefonnummer" })
				.required("Påkrevd")
		},
		email: {
			placeholder: "Epost *",
			width: "100%",
			value: PRODUCTION ? "" : "martin@reeltime.no",
			validation: Yup.string()
				.email("Ugyldig epost")
				.required("Påkrevd")
		}
	}
};

const ValdresKampanje = () => {
	const sharedUtils = useContext(Context);
	const formRef = useRef(null);
	return (
		<>
			<Container>
				<FullSection>
					<Ad src={adImage} />
					<Heading>Eiendomsmegler Valdres</Heading>
					<p>
						PrivatMegleren Valdres har to kontorer i Valdres, hvor det ene ligger i Fagernes og det andre ligger i
						sentrum av Beitostølen. Vi er godt representert i hele Valdres regionen på både hus, hytter og tomter. Våre
						kontorer er markedsleder på de største hyttedestinasjonene, men også på boligsalg i Valdres markerer vi oss
						tydelig.
					</p>
					<FormDescription>
						Ønsker du å vite mer om hvordan vi kan hjelpe deg med å selge bolig / hytte? Fyll ut skjema, så tar vi
						kontakt.
					</FormDescription>
					<Mutation mutation={CREATE_VALDRES_LEAD_MUTATION}>
						{createQrLead => (
							<Formik
								isInitialValid={!PRODUCTION}
								initialValues={{
									values: Object.entries(initialFormState.values).reduce(
										(acc, [k, v]) => ({
											...acc,
											[k]: v.value
										}),
										{}
									)
								}}
								validationSchema={Yup.object().shape({
									values: Yup.object().shape(
										Object.entries(initialFormState.values).reduce(
											(acc, [k, v]) => ({
												...acc,
												...(v.validation && {
													[k]: v.validation
												})
											}),
											{}
										)
									)
								})}
								onSubmit={(values, actions) => {
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: "smooth"
									});

									const submit = diggerId => {
										createQrLead({
											variables: {
												input: {
													values: {
														...values.values,
														comment: "Valdres Kampanje"
													},
													src: "valdreskampanje",
													referrer: "https://privatmegleren.no/valdreskampanje",
													digger_id: diggerId ? diggerId : null,
													isValuation: false
												}
											}
										}).catch(e => console.error(e));
									};

									sharedUtils.digger
										.formSubmit(
											values.values.zip
												? {
														zip: values.values.zip,
														type: "merketqr"
												  }
												: {}
										)
										.then(async res => {
											let id = res.result && res.result.id ? res.result.id : null;
											submit(id);
										})
										.catch(() => submit());

									console.log(values);
									setTimeout(() => {
										actions.setSubmitting(false);
										actions.setStatus("success");
									}, 2000);

									setTimeout(() => {
										actions.setStatus(null);
										actions.resetForm();
									}, 4000);
								}}
								render={({
									handleSubmit,
									handleChange,
									handleBlur,
									setFieldValue,
									values,
									touched,
									errors,
									isValid,
									isSubmitting,
									status
								}) => (
									<Form onSubmit={handleSubmit} ref={formRef}>
										{isSubmitting || status === "success" ? (
											<Status isSubmitting={isSubmitting} status={status} />
										) : null}
										{Object.entries(values.values)
											.filter(([k]) => k !== "comment")
											.map(([k, v]) => (
												<FormField
													key={k}
													fieldName={k}
													type="text"
													name={`values.${k}`}
													width={
														initialFormState.values[k] && initialFormState.values[k].width
															? initialFormState.values[k].width
															: null
													}
													placeholder={initialFormState.values[k]?.placeholder}
													touched={touched && touched.values && touched.values[k] ? true : false}
													error={errors && errors.values && errors.values[k]}
													value={values.values[k]}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											))}
										<SubmitButton primary text="Send inn skjema" isValid={isValid ? true : false} />
										<PrivacyLink href="/personvern">Personvernpolicy</PrivacyLink>
									</Form>
								)}
							/>
						)}
					</Mutation>
				</FullSection>
			</Container>
		</>
	);
};

const PrivacyLink = styled.a`
	width: 100%;
	margin-top: 40px;
	font-size: 1.1rem;
	color: white;
	text-align: center;
	text-decoration: none;
`;

const Heading = styled.h1``;

const Container = styled.div`
	padding: 150px 18px 0 18px;
	max-width: 1040px;
	margin: 0 auto;
	align-self: center;
`;

const FullSection = styled.section`
	display: flex;
	flex-flow: column;
	text-align: center;
	max-width: 720px;
	margin: 0 auto;
`;

const Form = styled.form`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-bottom: 96px;

	button {
		width: 100%;
		font-size: 24px;
	}
`;

const FormDescription = styled.p`
	display: block;
	margin: 0 0 48px 0;
	font-weight: 600;
`;

const Ad = styled.img`
	width: 100%;
	max-width: 450px;
	margin: 0 auto 50px auto;
`;

export default ValdresKampanje;
